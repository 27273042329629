<template>
  <b-container fluid class="bv-example-row">
    <b-navbar toggleable="sm" type="light" variant="light">
      <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
      <b-navbar-brand href="/">PORTAL V6.9</b-navbar-brand>
      <b-collapse id="nav-text-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="/global-products" v-if="showAll">Таблица товаров</b-nav-item>
<!--          <b-nav-item href="/pp" v-if="showAll">Товары/цены</b-nav-item>-->
          <b-nav-item href="/feedback" v-if="showAll">Поиск userID из отзывов</b-nav-item>
          <b-nav-item href="/hydra-table" v-if="showAll">Гидроглайды</b-nav-item>
          <b-nav-item href="/acuvue-report" v-if="showAll">Баллы Acuvue</b-nav-item>
          <b-nav-item href="/acuvue-table" v-if="showAll || showAcuvue">Цены Acuvue</b-nav-item>
<!--          <b-nav-item href="/logs-global-products">Лог ошибки в карточках</b-nav-item>-->
          <b-nav-item-dropdown text="WBviews" right v-if="showAll">
            <b-dropdown-item href="/wbviews">Выкупы</b-dropdown-item>
            <b-dropdown-item href="/wbviews/users">Кабинеты</b-dropdown-item>
            <b-dropdown-item href="/wbviews/cards">Карты</b-dropdown-item>
            <b-dropdown-item href="/wbviews/couriers">Курьеры</b-dropdown-item>
<!--            <b-dropdown-item href="#">RU</b-dropdown-item>-->
<!--            <b-dropdown-item href="#">FA</b-dropdown-item>-->
          </b-nav-item-dropdown>

          <b-nav-item href="/quiz" v-if="showAll || showQuiz">QUIZ</b-nav-item>

<!--          <b-nav-item href="/wb-feedbacks" v-if="showAll || showFeedbacks">Отзывы wb</b-nav-item>-->
          <b-nav-item-dropdown text="Отзывы wb" right v-if="showAll || showFeedbacks">
            <b-dropdown-item href="/wb-feedbacks">Вопросы/Отзывы</b-dropdown-item>
            <b-dropdown-item href="/wb-feedbacks/archive">Архив</b-dropdown-item>
            <b-dropdown-item href="/global-products"  >Каталог товаров</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>

    </b-navbar>
    <b-row class="mt-2">
      <b-col>
        <router-view />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data: () => ({
    title: 'title',
    showAll: false,
    showAcuvue: false,
    showQuiz: false,
    showFeedbacks: false,
  }),
  name: "Home-app",
  mounted() {
    // this.$cookies.remove("dildo-token")
    // this.$cookies.remove("dildo-role")
    const dildoToken = this.$cookies.get("dildo-token");
    const role = this.$cookies.get("dildo-role")
    if (!dildoToken) this.$router.push({path: "/login"});

    if (dildoToken && role=='glava') {
      this.showAll=true
    }
    if (dildoToken && role=='acuvue') {
      this.showAcuvue=true
    }
    if (dildoToken && role=='quiz') {
      this.showQuiz=true
    }
    if (dildoToken && role=='feedbacks') {
      this.showFeedbacks=true
    }
  }
};
</script>
