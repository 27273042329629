import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: () =>
        import("../views/Index.vue")
  },
  {
    path: "/list2",
    name: "Home",
    component: () =>
        import("../views/Main.vue")
  },
  {
    path: "/pp",
    name: "products-and-price",
    component: () =>
        import("../ProductsAndPrice/PPmain")
  },
  {
    path: "/list",
    name: "old products",
    component: () =>
        import("../views/OldProd.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () =>
        import("../views/Login.vue")
  },
  {
    path: "/feedback",
    name: "feedback",
    component: () =>
        import("../views/Feedback.vue")
  },
  {
    path: "/logs-prices",
    name: "logsprices",
    component: () =>
        import("../views/LogsPrices.vue")
  },
  {
    path: "/global-products",
    name: "globalproducts",
    component: () =>
        import("../views/GlobalTable.vue")
  },
  {
    path: "/logs-global-products",
    name: "globalproductslog",
    component: () =>
        import("../views/LogsGlobalTable.vue")
  },
  {
    path: "/wbviews",
    name: "wbviews",
    component: () =>
        import("../WbViews/WbViews.vue")
  },
  {
    path: "/wbviews/users",
    name: "wbviewsUsers",
    component: () =>
        import("../WbViews/Users/usersMain.vue")
  },
  {
    path: "/wbviews/cards",
    name: "wbviewsCards",
    component: () =>
        import("../WbViews/Cards/cardsList")
  },
  {
    path: "/wbviews/couriers",
    name: "wbviewsCouriers",
    component: () =>
        import("../WbViews/Courier/CourierList")
  },
  {
    path: "/quiz",
    name: "quiz",
    component: () =>
        import("../Quiz/Quiz")
  },
  {
    path: "/sales-report",
    name: "sales-report",
    component: () =>
        import("../views/SalesReport")
  },
  {
    path: "/acuvue-report",
    name: "acuvue-report",
    component: () =>
        import("../AcuvueLand/ViewAcuvue")
  },
  {
    path: "/acuvue-table",
    name: "acuvue-table",
    component: () =>
        import("../AcuvueTable/TableAcuvue")
  },
  {
    path: "/hydra-table",
    name: "hydra-table",
    component: () =>
        import("../HydraTable/TableHydraglyde.vue")
  },
  {
    path: "/pp-v5",
    name: "product-table-v5",
    component: () =>
        import("../ProductTableV5/TableV5.vue")
  },
  {
    path: "/wb-feedbacks",
    name: "wb-feedbacks",
    component: () =>
        import("../WbFeedbacks/WbFeedbacksMain.vue")
  },
  {
    path: "/wb-feedbacks/archive",
    name: "wb-feedbacks-archive",
    component: () =>
        import("../WbFeedbacks/WbFeedbacksArchive.vue")
  },
  {
    path: "/wb-feedbacks/catalog",
    name: "wb-feedbacks-catalog",
    component: () =>
        import("../WbFeedbacks/catalog/WbFeedbacksCatalog.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
