import Vue from 'vue'
import router from "./router";
import VueCookies from 'vue-cookies'
import VCalendar from 'v-calendar';
import App from './App.vue'
// import Dayjs from 'vue-dayjs'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueCookies)
Vue.use(VCalendar, {
   // Use <vc-calendar /> instead of <v-calendar />
});
// Vue.use(Dayjs)

Vue.$cookies.config('60d')

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
